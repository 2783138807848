import { api } from '@/services/Api';
import { Button, Modal, ModalBody, ModalContent, ModalHeader, ModalProps } from '@heroui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { LucideSwitchCamera, LucideTrash } from 'lucide-react';
import { useState } from 'react';
import { toast } from 'sonner';

interface ChangeAvatarModalProps extends Omit<ModalProps, 'children'> {
	current_profile_picture: string;
}
export default function ChangeAvatarModal({ current_profile_picture, isOpen, onOpenChange, onClose, ...props }: ChangeAvatarModalProps) {
	const queryClient = useQueryClient();
	const [file, setFile] = useState<File | null | undefined>();

	function handleFileChange(e: any) {
		e.preventDefault();
		if (isDeletingAvatar || isPostingAvatar) return;
		if (e.target.files && e.target.files.length > 0) {
			setFile(e.target.files[0]);
		}
	}

	const deleteAvatar = async () => {
		setFile(null);
		await api
			.delete('/usuario/avatar')
			.then(async () => {
				toast.success(`Seu Avatar foi removido com sucesso!`);
				await queryClient.invalidateQueries({ queryKey: ['get-current-user'] });
				return onClose?.();
			})
			.catch((err) => {
				return toast.error(err.response.data.message);
			});
	};

	const { mutateAsync: handleDeleteAvatar, isPending: isDeletingAvatar } = useMutation({
		mutationFn: async () => await deleteAvatar(),
	});

	const postAvatar = async () => {
		if (file) {
			const formData = new FormData();
			formData.append('file', file);

			await api
				.post('/usuario/avatar', formData, {
					method: 'POST',
					headers: {
						'Content-Type': `multipart/form-data: boundary=add-random-characters`,
						Accept: 'application/json',
						type: 'formData',
					},
				})
				.then(async () => {
					toast.success(`Seu Avatar foi alterado com sucesso!`);
					await queryClient.invalidateQueries({ queryKey: ['get-current-user'] });
					return onClose?.();
				})
				.catch((err) => {
					return toast.error(err.response.data.message);
				});
		}
	};

	const { mutateAsync: handlePostAvatar, isPending: isPostingAvatar } = useMutation({
		mutationFn: async () => await postAvatar(),
	});

	return (
		<Modal
			backdrop="opaque"
			size={'lg'}
			shouldBlockScroll
			isOpen={isOpen}
			onOpenChange={onOpenChange}
			onClose={onClose}
			placement="center"
			className="bg-muted"
			classNames={{
				backdrop: 'bg-gradient-to-t from-background/50 to-background/70 ',
				closeButton: 'z-50',
			}}
			{...props}
		>
			<ModalContent className="rounded-md bg-background p-4">
				<ModalHeader className="relative overflow-hidden p-1 text-lg">
					<h1>Trocar Foto de Perfil</h1>
				</ModalHeader>
				<ModalBody className="flex h-full w-full flex-col justify-center p-4 lg:justify-start">
					<div className="m-2 flex items-center justify-center">
						<div className="p-2">
							<input
								accept="image/png, image/jpg, image/jpeg, image/webp"
								multiple={false}
								id="picture"
								className="hidden"
								type="file"
								onChange={handleFileChange}
							/>

							<label
								htmlFor="picture"
								className="w-full cursor-pointer data-[is-disabled=true]:pointer-events-none"
								data-is-disabled={isPostingAvatar || isDeletingAvatar}
							>
								<img
									src={file ? (window.URL || webkitURL).createObjectURL(file) : current_profile_picture!}
									className="z-50 h-[200px] w-[200px] cursor-pointer rounded-full object-cover sm:h-[280px] sm:w-[280px]"
									alt="Foto Perfil"
								/>
							</label>
						</div>
					</div>
					<div className="my-3 flex flex-col justify-between gap-3 xsm:flex-row sm:gap-6">
						<Button
							variant={'flat'}
							color="danger"
							className="w-full"
							onPress={() => handleDeleteAvatar()}
							endContent={<LucideTrash size={20} />}
							isDisabled={isPostingAvatar || isDeletingAvatar || current_profile_picture === 'null'}
							isLoading={isDeletingAvatar}
						>
							{isDeletingAvatar ? 'Removendo...' : 'Remover'}
						</Button>
						<label
							htmlFor="picture"
							className="w-full cursor-pointer data-[is-disabled=true]:pointer-events-none"
							data-is-disabled={isPostingAvatar || isDeletingAvatar}
						>
							<Button
								variant={'flat'}
								color="secondary"
								className="-z-10 w-full"
								endContent={<LucideSwitchCamera size={20} />}
								isDisabled={isPostingAvatar || isDeletingAvatar}
							>
								Alterar
							</Button>
						</label>
					</div>
					{file && (
						<Button
							variant={'flat'}
							color="success"
							onPress={() => handlePostAvatar()}
							isLoading={isPostingAvatar || isDeletingAvatar}
							className=" mx-auto w-full gm:w-[50%]"
						>
							{isPostingAvatar ? 'Salvando...' : 'Salvar'}
						</Button>
					)}
				</ModalBody>
			</ModalContent>
		</Modal>
	);
}
