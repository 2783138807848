import ChangeAvatarModal from '@/components/Menus/new-right-menu/components/change-avatar-modal';
import { getCurrentUser } from '@/functions/react-query/get/user/get-current-user';
import LoadingPage from '@/pages/Loading';
import { useDisclosure } from '@heroui/react';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Fragment, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { toast } from 'sonner';

export const VerifyIfHasProfilePicture = () => {
	const { data: user, isLoading } = useQuery({
		queryKey: ['get-current-user'],
		queryFn: getCurrentUser,
		staleTime: 5000,
		placeholderData: keepPreviousData,
	});

	const modalChangeAvatar = useDisclosure();

	useEffect(() => {
		if (user && (!user.avatar || user?.avatar.endsWith('/')) && !modalChangeAvatar.isOpen) {
			modalChangeAvatar.onOpen();
			toast.info('Adicione sua foto de perfil e deixe seu perfil mais completo! 😊', { position: 'top-center' });
		}
	}, [user, modalChangeAvatar]);

	if (isLoading) return <LoadingPage />;

	return (
		<Fragment>
			<Outlet />
			<ChangeAvatarModal
				isOpen={modalChangeAvatar.isOpen}
				onOpenChange={modalChangeAvatar.onOpenChange}
				onClose={modalChangeAvatar.onClose}
				isDismissable={false}
				isKeyboardDismissDisabled
				hideCloseButton
				current_profile_picture={String(user?.avatar)}
			/>
		</Fragment>
	);
};
