//* NO SPECIFIC CONTENT IMPORTS
import { PERMISSAO } from '@/utils/constants/permissions';
import { AnimatePresence } from 'framer-motion';
import { lazy } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

//* Controllers
import { AllUsers } from '@/pages/Admin/acessPermission/AllUsers';
import { PermissionByUser } from '@/pages/Admin/acessPermission/PermissionByUser';
import { CopyPermissionProvider } from './CopyPermission';

//* Validators
import { PrivateRoute } from './PrivateRoute';
import { VerifyPermissionByRoute } from './VerifyPermissionByRoute';
import { VerifyUnityColaborator } from './VerifyUnityColaborator';
import { VerifyWorkJourney } from './VerifyWorkJourney';

//* Handler
import { useConnection } from '@/contexts/pages/StatusConnection/StatusConnection';
import WithoutConnection from '@/pages/WithoutConnection';
import { AdminRoutes } from './AdminRoutes';
import { VerifyIfHasProfilePicture } from './verify-if-has-profile-picture';

const Navbar = lazy(() => import('../components/componentsCN/ui/navbar'));
const Dashboard = lazy(() => import('@/pages/Dashboard'));
const DashboardAdmin = lazy(() => import('@/pages/Admin/DashboardAdmin'));

//! USER ROUTES
//* Aniversariantes
const Aniversariantes = lazy(() => import('@/pages/Aniversariantes'));

//*Coisa Boa
const CoisaBoa = lazy(() => import('@/pages/CoisaBoa'));

//*Erros
const ErrosSaoTesouros = lazy(() => import('@/pages/Erros'));

//*Impossivel
const Impossivel = lazy(() => import('@/pages/Impossivel'));

//*Quase Acidente
const QuaseAcidente = lazy(() => import('@/pages/QuaseAcidente'));

//*TvMarins
const TvMarins = lazy(() => import('@/pages/TvMarins'));

//*Resumo Livro
const ResumoLivro = lazy(() => import('@/pages/ResumoLivro'));
const ResumoLivroId = lazy(() => import('@/pages/ResumoLivro/ResumoLivroId'));
const Biblioteca = lazy(() => import('@/pages/ResumoLivro/Biblioteca'));

//*Papo com a Direção
const PapoComDirecao = lazy(() => import('@/pages/PapoComDirecao'));
const PaposAntigos = lazy(() => import('@/pages/PapoComDirecao/PaposAntigos'));

//*Extrato
const Extrato = lazy(() => import('@/pages/Usuario/Extrato'));

//*Notificações
const Notificacoes = lazy(() => import('@/pages/Usuario/Notificacoes'));

//*Convênios
const Convenios = lazy(() => import('@/pages/Convenios'));
const Detalhes = lazy(() => import('@/pages/Convenios/Detalhes'));

//*Resumo da Live
const NewLive = lazy(() => import('@/pages/LiveEscrita'));
const ResumoLiveId = lazy(() => import('@/pages/LiveEscrita/LiveId'));
const AllLivesUser = lazy(() => import('@/pages/LiveEscrita/AllLives'));

//*Café com a direção
const CafeDirecaoInscricao = lazy(() => import('@/pages/CafeDirecao'));
const CafeDirecaoParticipantes = lazy(() => import('@/pages/CafeDirecao/Participantes'));

//*Video da Semana
//const VideoDaSemana = lazy(() => import('@/pages/VideoDaSemana'));
//const VideoDaSemanaId = lazy(() => import('@/pages/VideoDaSemana/VideoId'));

//*Frase da Semana
const FraseDaSemana = lazy(() => import('@/pages/FraseDaSemana'));
const FraseDaSemanaId = lazy(() => import('@/pages/FraseDaSemana/FraseDaSemanaId'));

//*Links Pormade
const LinksPormade = lazy(() => import('@/pages/LinksPormade'));

//*Error Page
const Error404 = lazy(() => import('@/pages/Error404'));

//*Developers of first PETIM phase
const OurTeam = lazy(() => import('@/pages/NossaEquipe'));

//* Notifications
const Notifications = lazy(() => import('@/pages/Admin/Notifications'));

//*Kiosk
const ReservaDaAssociacao = lazy(() => import('@/pages/ReservaDaAssociacao'));

//*Draws
const Draws = lazy(() => import('@/pages/Sorteios'));

//! ADMIN ROUTES
//*Coisa Boa
const EnviosCoisaBoa = lazy(() => import('@/pages/Admin/CoisaBoa/Envios'));

//*Impossivel
const EnviosImpossivel = lazy(() => import('@/pages/Admin/ImpossivelAdmin/Envios'));

//*Erros
const EnviosErros = lazy(() => import('@/pages/Admin/ErrosAdmin/Envios'));

//*Quase Acidente
const EnviosQuaseAcidente = lazy(() => import('@/pages/Admin/QuaseAcidenteAdmin/Envios'));

//*Tv Marins
const EnviosTvMarins = lazy(() => import('@/pages/Admin/TvMarinsAdmin/Envios'));

//*Resumo do Livro
const ResumosLivro = lazy(() => import('@/pages/Admin/Livro/Envios'));
const LivrosCadastrados = lazy(() => import('@/pages/Admin/Livro/LivrosCadastrados'));
const GerenciarPaginaLivro = lazy(() => import('@/pages/Admin/Livro/GerenciarPaginaLivro'));

//*Video da Semana
//const EnviosVideoId = lazy(() => import('@/pages/Admin/VideoDaSemana/EnviosVideoId'));
//const CadastrarVideoDaSemana = lazy(() => import('@/pages/Admin/VideoDaSemana/CadastrarVideoDaSemana'));
//const EditarVideoDaSemana = lazy(() => import('@/pages/Admin/VideoDaSemana/EditarVideoDaSemana'));
//const VideosDaSemanaCadastrados = lazy(() => import('@/pages/Admin/VideoDaSemana/VideosCadastrados'));

//*Frase da Semana
const EnviosFraseDaSemanaId = lazy(() => import('@/pages/Admin/FraseDaSemanaAdmin/EnviosFraseId'));
const CadastrarFraseDaSemana = lazy(() => import('@/pages/Admin/FraseDaSemanaAdmin/CadastrarFraseDaSemana'));
const EditarFraseDaSemana = lazy(() => import('@/pages/Admin/FraseDaSemanaAdmin/EditarFraseDaSemana'));
const FrasesDaSemanaCadastradas = lazy(() => import('@/pages/Admin/FraseDaSemanaAdmin/FrasesCadastradas'));

//*Papo com a Direção
const VerEnviosPapo = lazy(() => import('@/pages/Admin/PapoComDirecaoAdmin/VerEnviosPapoId'));
const VerResumosPapo = lazy(() => import('@/pages/Admin/PapoComDirecaoAdmin/VerEnviosPapoId/resumos'));
const CadastrarPapoDirecao = lazy(() => import('@/pages/Admin/PapoComDirecaoAdmin/CadastrarPapo'));
const PaposCadastrados = lazy(() => import('@/pages/Admin/PapoComDirecaoAdmin/PaposCadastrados'));
const ExcessoesPapo = lazy(() => import('@/pages/Admin/acessPermission/Excessoes'));
const EditarPapo = lazy(() => import('@/pages/Admin/PapoComDirecaoAdmin/EditarPapo'));
const IniciarPapoDirecao = lazy(() => import('@/pages/Admin/PapoComDirecaoAdmin/IniciarPapoId'));

//*Resumo da Live
const VerEnviosLiveId = lazy(() => import('@/pages/Admin/NewLiveAdmin/VerEnviosLiveId'));
const NewLiveAdmin = lazy(() => import('@/pages/Admin/NewLiveAdmin/CadastrarLive'));
const EditLive = lazy(() => import('@/pages/Admin/NewLiveAdmin/EditarLive'));
const TodasLives = lazy(() => import('@/pages/Admin/NewLiveAdmin/TodasLives'));
// const InsercoesManuaisLive = lazy(() => import('@/pages/Admin/NewLiveAdmin/InsercaoManual'));
const VerLives = lazy(() =>
	import('@/pages/Admin/NewLiveAdmin/AbrirLive').then((module) => {
		return { default: module.VerLives };
	}),
);

//*Café com a direção
const CafesCadastrados = lazy(() => import('@/pages/Admin/CafeDirecao'));

//*Lançamentos - (CIPA e Outros)
const InsercaoManualPetim = lazy(() => import('@/pages/Admin/PetimManualInsertion'));
const CadastrarLancamento = lazy(() => import('@/pages/Admin/LancamentosAdm/CadastrarLancamentos'));
const EditarLancamento = lazy(() => import('@/pages/Admin/LancamentosAdm/EditarLancamentos'));
const Lancamentos = lazy(() =>
	import('@/pages/Admin/LancamentosAdm/VerTodosLancamentos').then((module) => {
		return { default: module.Lancamentos };
	}),
);

//*Plataforma Marins
const AnexarPlataformaMarins = lazy(() => import('@/pages/Admin/PlataformaMarinsAdmin/GerenciarAnexos'));
const InsercoesManuaisPlataformaMarins = lazy(() => import('@/pages/Admin/PlataformaMarinsAdmin/InsercaoManual'));
const Anexo = lazy(() => import('@/pages/Admin/PlataformaMarinsAdmin/Anexo'));

//*Links Pormade
const LinksPormadeAdmin = lazy(() => import('@/pages/Admin/LinksPormadeAdmin'));

//*Extrato
const ExtratoSaida = lazy(() => import('@/pages/Admin/ExtratoAdmin'));
const ExtratoId = lazy(() => import('@/pages/Admin/ExtratoAdmin/ExtratoColaborador'));
const Colaboradores = lazy(() => import('@/pages/Admin/Colaboradores'));
const FotosColaboradores = lazy(() => import('@/pages/Admin/Colaboradores/Fotos'));

//*Controles de Acesso
const UserPermissionsControl = lazy(() => import('@/pages/Admin/acessPermission/UserWithoutPermission'));
const UserAdminPermission = lazy(() =>
	import('@/pages/Admin/acessPermission/UserAdmin').then((module) => {
		return { default: module.UserAdminPermission };
	}),
);

//*Sorteios
//const SorteioVideoDaSemana = lazy(() => import('@/pages/Admin/VideoDaSemana/Sorteio'));
const SorteioFraseDaSemana = lazy(() => import('@/pages/Admin/FraseDaSemanaAdmin/Sorteio'));
const SorteioLiveEscrita = lazy(() => import('@/pages/Admin/NewLiveAdmin/Sorteio'));
const SorteioPapo = lazy(() => import('@/pages/Admin/PapoComDirecaoAdmin/Sorteio'));

//*Sorteados
//const SorteadosVideoId = lazy(() => import('@/pages/Admin/VideoDaSemana/SorteadosVideoId'));
const SorteadosFraseDaSemanaId = lazy(() => import('@/pages/Admin/FraseDaSemanaAdmin/SorteadosFraseId'));
const SorteadosLiveId = lazy(() => import('@/pages/Admin/NewLiveAdmin/SorteadosLiveId'));
const SorteadosPapoId = lazy(() => import('@/pages/Admin/PapoComDirecaoAdmin/SorteadosPapoId'));

//*Convênios
const ConveniosAdmin = lazy(() =>
	import('@/pages/Admin/ConveniosAdmin').then((module) => {
		return { default: module.ConveniosAdmin };
	}),
);
const AddConvenios = lazy(() =>
	import('@/pages/Admin/ConveniosAdmin/CadastroConvenioAdmin/AddConvenios').then((module) => {
		return { default: module.AddConvenios };
	}),
);
const AddLocal = lazy(() =>
	import('@/pages/Admin/ConveniosAdmin/CadastroConvenioAdmin/AddLocal').then((module) => {
		return { default: module.AddLocal };
	}),
);
const EditarConvenios = lazy(() =>
	import('@/pages/Admin/ConveniosAdmin/EditarConveniosAdmin/EditarConvenios').then((module) => {
		return { default: module.EditarConvenios };
	}),
);
const EditarLocal = lazy(() =>
	import('@/pages/Admin/ConveniosAdmin/EditarConveniosAdmin/EditarLocal').then((module) => {
		return { default: module.EditarLocal };
	}),
);

//* Mensagens Login
const LoginMessages = lazy(() => import('@/pages/Admin/LoginMessages'));

//* kiosk
const EditarQuiosque = lazy(() => import('@/pages/Admin/ReservaQuiosqueAdmin/EditarReservasQuiosque'));
const VerReservasQuiosque = lazy(() => import('@/pages/Admin/ReservaQuiosqueAdmin/VerReservasQuiosque'));

//*Inactivated routes - if you want to reactivate, also reactive into mocks of left menu and navbar
/* const Setores = lazy(() => import('@/pages/Admin/Setores'));
	const CriarSetor = lazy(() => import('@/pages/Admin/Setores/CriarSetor'));
	const Setor = lazy(() => import('@/pages/Admin/Setores/Setor')); */

export default function Rotas() {
	const { isConnected } = useConnection();

	return (
		<AnimatePresence>
			<Router key={location.pathname}>
				{!isConnected && <WithoutConnection />}
				{isConnected && <Navbar />}
				<Routes>
					<Route element={<PrivateRoute />}>
						<Route element={<VerifyUnityColaborator />}>
							<Route path="/papoComDirecao" element={<PapoComDirecao />} />
							<Route path="/papoComDirecao/antigos" element={<PaposAntigos />} />
						</Route>
						<Route element={<VerifyIfHasProfilePicture />}>
							<Route path="/" element={<Dashboard />} />
						</Route>
						<Route path="/aniversariantes" element={<Aniversariantes />} />
						<Route path="/coisaboa" element={<CoisaBoa />} />
						<Route path="/erros" element={<ErrosSaoTesouros />} />
						<Route path="/quaseAcidente" element={<QuaseAcidente />} />
						<Route path="/impossivel" element={<Impossivel />} />
						<Route path="/reservaDaAssociacao" element={<ReservaDaAssociacao />} />
						<Route element={<VerifyWorkJourney />}>
							<Route path="/resumolivro" element={<ResumoLivro />} />
							<Route path="/resumolivro/biblioteca" element={<Biblioteca />} />
							<Route path="/resumolivro/:idPaginas" element={<ResumoLivroId />} />
						</Route>
						<Route element={<VerifyWorkJourney />}>
							<Route path="/tvmarins" element={<TvMarins />} />
						</Route>
						<Route path="/extrato" element={<Extrato />} />
						<Route path="/notificacoes" element={<Notificacoes />} />
						<Route path="/convenios" element={<Convenios />} />
						<Route path="/convenios/detalhes/:id" element={<Detalhes />} />
						<Route element={<VerifyWorkJourney />}>
							<Route path="/newLive" element={<NewLive />} />
							<Route path="/newLive/:idLive" element={<ResumoLiveId />} />
						</Route>
						<Route path="/allLives" element={<AllLivesUser />} />
						<Route path="/cafe-direcao/:id/inscricao" element={<CafeDirecaoInscricao />} />
						<Route path="/cafe-direcao/:id/participantes" element={<CafeDirecaoParticipantes />} />
						//*Inactivated routes - if you want to reactivate, also reactive into mocks of left menu and navbar
						{/* <Route element={<VerifyUnityColaborator />}>
									<Route path="/videoDaSemana" element={<VideoDaSemana />} />
									<Route path="/videoDaSemana/:idVideo" element={<VideoDaSemanaId />} />
								</Route> */}
						<Route path="/fraseDaSemana" element={<FraseDaSemana />} />
						<Route path="/frasesPassadas" element={<FraseDaSemana />} />
						<Route path="/fraseDaSemana/:idFrase" element={<FraseDaSemanaId />} />
						<Route path="/nossosLinks" element={<LinksPormade />} />
						<Route path="/NossaEquipe" element={<OurTeam />} />
						<Route path="/sorteios" element={<Draws />} />
						<Route element={<AdminRoutes />}>
							<Route path="/admin" element={<DashboardAdmin />} />
							<Route element={<VerifyPermissionByRoute permission={PERMISSAO.GERENCIAR_GESTORES} />}>
								<Route element={<CopyPermissionProvider />}>
									<Route path="/admin/permissoes/adicionar" element={<UserPermissionsControl />} />
									<Route path="/admin/permissoes/" element={<UserAdminPermission />} />
									<Route path="/admin/permissoes/usuarios" element={<AllUsers />} />
									<Route path="/admin/permissoes/usuario/:id" element={<PermissionByUser />} />
									<Route path="/admin/permissoes/excessoes" element={<ExcessoesPapo />} />
								</Route>
							</Route>
							<Route element={<VerifyPermissionByRoute permission={PERMISSAO.VER_ENVIOS_COISA_BOA} />}>
								<Route path="/admin/coisaboa/envios" element={<EnviosCoisaBoa />} />
							</Route>
							<Route element={<VerifyPermissionByRoute permission={PERMISSAO.VER_ENVIOS_IMPOSSIVEL} />}>
								<Route path="/admin/impossivel/envios" element={<EnviosImpossivel />} />
							</Route>
							<Route element={<VerifyPermissionByRoute permission={PERMISSAO.VER_ENVIOS_ERROS} />}>
								<Route path="/admin/erros/envios" element={<EnviosErros />} />
							</Route>
							<Route element={<VerifyPermissionByRoute permission={PERMISSAO.VER_ENVIOS_RESUMO_LIVRO} />}>
								<Route path="/admin/livro/envios" element={<ResumosLivro />} />
							</Route>
							<Route element={<VerifyPermissionByRoute permission={PERMISSAO.VER_ENVIOS_TV_MARINS} />}>
								<Route path="/admin/TvMarinsAdmin/Envios" element={<EnviosTvMarins />} />
							</Route>
							<Route element={<VerifyPermissionByRoute permission={PERMISSAO.PLATAFORMA_MARINS} />}>
								<Route path="/admin/PlataformaMarinsAdmin/gerenciar" element={<AnexarPlataformaMarins />} />
								<Route path="/admin/PlataformaMarinsAdmin/gerenciar/registro/:id" element={<Anexo />} />
								<Route path="/admin/PlataformaMarinsAdmin/gerenciar/manual" element={<InsercoesManuaisPlataformaMarins />} />
							</Route>
							<Route element={<VerifyPermissionByRoute permission={PERMISSAO.VER_ENVIOS_QUASE_ACIDENTE} />}>
								<Route path="/admin/quaseacidente/envios" element={<EnviosQuaseAcidente />} />
							</Route>
							<Route element={<VerifyPermissionByRoute permission={PERMISSAO.FINANCEIRO} />}>
								<Route path="/admin/extrato/retirar" element={<ExtratoSaida />} />
								<Route path="/admin/extrato/:idPessoa" element={<ExtratoId />} />
							</Route>
							<Route element={<VerifyPermissionByRoute permission={PERMISSAO.FRASE_DA_SEMANA} />}>
								<Route element={<VerifyPermissionByRoute permission={PERMISSAO.CADASTRAR_FRASE_DA_SEMANA} />}>
									<Route path="/admin/fraseDaSemana/criar" element={<CadastrarFraseDaSemana />} />
									<Route path="/admin/fraseDaSemana/editar/:fraseId" element={<EditarFraseDaSemana />} />
								</Route>
								<Route element={<VerifyPermissionByRoute permission={PERMISSAO.SORTEIO} />}>
									<Route path="/admin/fraseDaSemana/sorteio/:fraseId/:titulo" element={<SorteioFraseDaSemana />} />
									<Route path="/admin/fraseDaSemana/sorteados/:fraseId/:titulo" element={<SorteadosFraseDaSemanaId />} />
								</Route>
								<Route element={<VerifyPermissionByRoute permission={PERMISSAO.VER_ENVIOS_FRASE_DA_SEMANA} />}>
									<Route path="/admin/fraseDaSemana/envios/:fraseId" element={<EnviosFraseDaSemanaId />} />
								</Route>
								<Route path="/admin/fraseDaSemana/cadastradas" element={<FrasesDaSemanaCadastradas />} />
							</Route>
							//*Inactivated routes - if you want to reactivate, also reactive into mocks of left menu and navbar
							{/* <Route element={<VerifyPermissionByRoute permission={PERMISSAO.VIDEO_DA_SEMANA} />}>
								<Route element={<VerifyPermissionByRoute permission={PERMISSAO.CADASTRAR_VIDEO_DA_SEMANA} />}>
									<Route path="/admin/videoDaSemana/criar" element={<CadastrarVideoDaSemana />} />
									<Route path="/admin/videoDaSemana/editar/:videoId" element={<EditarVideoDaSemana />} />
								</Route>
								<Route element={<VerifyPermissionByRoute permission={PERMISSAO.SORTEIO} />}>
									<Route path="/admin/videoDaSemana/sorteio/:videoId/:titulo" element={<SorteioVideoDaSemana />} />
									<Route path="/admin/videoDaSemana/sorteados/:videoId/:titulo" element={<SorteadosVideoId />} />
								</Route>
								<Route element={<VerifyPermissionByRoute permission={PERMISSAO.VER_ENVIOS_VIDEO_DA_SEMANA} />}>
									<Route path="/admin/videoDaSemana/envios/:videoId" element={<EnviosVideoId />} />
								</Route>
								<Route path="/admin/videoDaSemana/cadastrados" element={<VideosDaSemanaCadastrados />} />
							</Route> */}
							<Route element={<VerifyPermissionByRoute permission={PERMISSAO.LIVE} />}>
								<Route element={<VerifyPermissionByRoute permission={PERMISSAO.CADASTRAR_LIVE} />}>
									<Route path="/Admin/NewLiveAdmin" element={<NewLiveAdmin />} />
									<Route path="/Admin/NewLiveAdmin/editarLive/:idLive/:titulo" element={<EditLive />} />
								</Route>

								<Route element={<VerifyPermissionByRoute permission={PERMISSAO.VER_ENVIOS_LIVE} />}>
									<Route path="/Admin/NewLiveAdmin/VerEnvios/:idLive/:titulo" element={<VerEnviosLiveId />} />
									<Route path="/Admin/NewLiveAdmin/livesCadastradas" element={<TodasLives />} />
									<Route path="/Admin/NewLiveAdmin/verLive/:idLive/:titulo" element={<VerLives />} />
								</Route>

								<Route element={<VerifyPermissionByRoute permission={PERMISSAO.CAFE_DIRECAO} />}>
									<Route element={<VerifyPermissionByRoute permission={PERMISSAO.GERENCIAR_CAFE_DIRECAO} />}>
										<Route path="/admin/cafe-direcao" element={<CafesCadastrados />} />
									</Route>
								</Route>
								<Route element={<VerifyPermissionByRoute permission={PERMISSAO.LANCAMENTO_MANUAL_LIVE} />}>
									//*Inactivated routes - if you want to reactivate, also reactive into mocks of left menu and navbar
									{/* <Route path="/Admin/NewLiveAdmin/cadastrarManual" element={<InsercoesManuaisLive />} /> */}
								</Route>

								<Route element={<VerifyPermissionByRoute permission={PERMISSAO.SORTEIO} />}>
									<Route path="/admin/NewLiveAdmin/sorteados/:idLive/:titulo" element={<SorteadosLiveId />} />
									<Route path="/Admin/NewLiveAdmin/Sorteio/:idLive/:titulo" element={<SorteioLiveEscrita />} />
								</Route>

								<Route element={<VerifyPermissionByRoute permission={PERMISSAO.CADASTRAR_LIVE} />}>
									<Route path="/admin/papoDirecao/criar" element={<CadastrarPapoDirecao />} />
									<Route path="/admin/papoDirecao/paposCadastrados" element={<PaposCadastrados />} />
									<Route path="/admin/papoDirecao/editarPapo/:idPapo" element={<EditarPapo />} />
									<Route path="/admin/papoDirecao/iniciarPapo/:idPapo/:titulo" element={<IniciarPapoDirecao />} />
									<Route path="/admin/papoDirecao/estatisticas/:idPapo/:titulo" element={<VerEnviosPapo />} />
									<Route path="/admin/papoDirecao/resumos/:idPapo/:titulo" element={<VerResumosPapo />} />
								</Route>
								<Route element={<VerifyPermissionByRoute permission={PERMISSAO.SORTEIO} />}>
									<Route path="/admin/papoDirecao/sorteados/:idPapo/:titulo" element={<SorteadosPapoId />} />
									<Route path="/admin/papoDirecao/Sorteio/:idPapo/:titulo" element={<SorteioPapo />} />
								</Route>
							</Route>
							<Route element={<VerifyPermissionByRoute permission={PERMISSAO.RESUMO_DO_LIVRO} />}>
								<Route path="/admin/livro/livroscadastrados" element={<LivrosCadastrados />} />
								<Route path="/admin/livro/gerenciarpaginalivro/:idLivro" element={<GerenciarPaginaLivro />} />
							</Route>
							<Route element={<VerifyPermissionByRoute permission={PERMISSAO.GERENCIAR_LINKS_PORMADE} />}>
								<Route path="/admin/linksPormade" element={<LinksPormadeAdmin />} />
							</Route>
							<Route element={<VerifyPermissionByRoute permission={PERMISSAO.GERENCIAR_PESSOAS} />}>
								<Route path="/admin/colaboradores" element={<Colaboradores />} />
							</Route>
							<Route element={<VerifyPermissionByRoute permission={PERMISSAO.BAIXAR_FOTOS} />}>
								<Route path="/admin/colaboradores/fotos" element={<FotosColaboradores />} />
							</Route>
							//*Inactivated routes - if you want to reactivate, also reactive into mocks of left menu and navbar
							{/* <Route element={<VerifyPermissionByRoute permission={PERMISSAO.CADASTRAR_SETOR} />}>
								<Route path="/admin/setor/criar" element={<CriarSetor />} />
								<Route path="/admin/setores" element={<Setores />} />
								<Route path="/admin/setores/setor/:nome/:id" element={<Setor />} />
							</Route> */}
							<Route element={<VerifyPermissionByRoute permission={PERMISSAO.LANCAMENTO_MANUAL_PETIM} />}>
								<Route path="/admin/lancamento/petim" element={<InsercaoManualPetim />} />
							</Route>
							<Route element={<VerifyPermissionByRoute permission={PERMISSAO.LANCAMENTO} />}>
								<Route element={<VerifyPermissionByRoute permission={PERMISSAO.GERENCIAR_LANCAMENTO} />}>
									<Route path="/admin/lancamento/gerenciarLancamentos" element={<Lancamentos />} />
									<Route path="/admin/lancamento/cadastrarLancamento" element={<CadastrarLancamento />} />
									<Route path="/admin/lancamento/editarLancamento/:idLancamento" element={<EditarLancamento />} />
								</Route>
							</Route>
							<Route element={<VerifyPermissionByRoute permission={PERMISSAO.CONVENIOS} />}>
								<Route path="/admin/convenio" element={<ConveniosAdmin />} />
								<Route path="/admin/convenio/adicionar" element={<AddConvenios />} />
								<Route path="/admin/convenio/adicionar/:id/local" element={<AddLocal />} />
								<Route path="/admin/convenio/:id/editar" element={<EditarConvenios />} />
								<Route path="/admin/convenio/:id/editar/local/editar" element={<EditarLocal />} />
							</Route>
							<Route element={<VerifyPermissionByRoute permission={PERMISSAO.GERENCIAR_QUIOSQUE} />}>
								<Route path="/admin/quiosque" element={<VerReservasQuiosque />} />
								<Route path="/admin/quiosque/edit/:id" element={<EditarQuiosque />} />
							</Route>
							<Route element={<VerifyPermissionByRoute permission={PERMISSAO.GERENCIAR_MENSAGEM_LOGIN} />}>
								<Route element={<VerifyPermissionByRoute permission={PERMISSAO.GERENCIAR_MENSAGEM_LOGIN} />}>
									<Route path="/admin/mensagens-login" element={<LoginMessages />} />
								</Route>
							</Route>
							<Route element={<VerifyPermissionByRoute permission={PERMISSAO.GERENCIAR_NOTIFICACOES} />}>
								<Route element={<VerifyPermissionByRoute permission={PERMISSAO.GERENCIAR_NOTIFICACOES} />}>
									<Route path="/admin/notificacoes" element={<Notifications />} />
								</Route>
							</Route>
						</Route>
						<Route path="*" element={<Error404 />} />
					</Route>
				</Routes>
			</Router>
		</AnimatePresence>
	);
}
